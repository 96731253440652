.card {
  margin-bottom: 2rem; }

.card__image {
  margin-bottom: 0.5rem;
  img {
    max-width: 100%;
    height: auto; } }

.card__text {
  h2, h3 {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: $color-primary; }
  p {
    margin: 0; } }

.card__cta {
  margin-top: 0.5rem;

  a:before {
    content: '\2192\00A0'; } }
