input, select, button, textarea {
  font-family: $font-regular;
  font-weight: 400;
  font-size: inherit; }

input[type="text"],
input[type="email"],
input[type="password"],
select,
textarea {
  border: 1px solid $color-grey-dark;
  padding: 0 1rem;
  height: 40px;
  width: 100%;
  border-radius: 1px; }

select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m17.3786797 16-5.8786797-5.8786797c-.5857864-.58578639-.5857864-1.53553386 0-2.1213203.5857864-.58578644 1.5355339-.58578644 2.1213203 0l6.5857865 6.5857864c.7810486.7810486.7810486 2.0473786 0 2.8284272l-6.5857865 6.5857864c-.5857864.5857864-1.5355339.5857864-2.1213203 0-.5857864-.5857864-.5857864-1.5355339 0-2.1213203z' transform='matrix(0 1 -1 0 32.03 -.03)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 24px;

  &::ms-expand {
    display: none; } }

input[type="submit"] {
  height: 40px;
  border: 0;
  border-radius: 20px;
  font-weight: 700;
  padding: 0 2rem;
  background-color: $color-primary;
  color: $color-light; }

label {
  font-weight: 700; }


.form-default {

  > div:not(.check) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem; }

  .check {
    margin-bottom: 2rem;
    label {
      display: inline-flex;
      align-items: center;
      margin-right: 1rem;

      input {
        margin-right: 8px; } } }

  label {
    display: flex;
    padding-top: 8px;
    flex: 0 0 100%;
    @media (min-width: 1000px) {
      min-height: 40px;
      flex: 0 0 15em; } }

  label[for="pi_cf-datenschutz"] {
    display: block; }

  input[type="text"],
  select,
  textarea {
    flex: 1; }

  input#pi_cf-zip {
    margin-right: 1rem;
    flex: 0 0 8rem; } }


// Overwrites Newsletter Form

.nl-form-body {
  background-color: transparent !important;
  margin: 0 !important;
  width: auto !important;
  font-family: inherit !important; }

.nl-form-body .element {
  padding: 0 !important;
  margin-top: 0.5rem !important; }

.nl-form-body button {
  background: $color-primary !important;
  color: white !important;
  height: 40px;
  border-radius: 20px;
  border: 0 !important;
  font-weight: bold; }

.nl-form-body p.descr {
  margin-top: 0.125rem !important; }

.nl-form-body button {
  width: auto !important;
  min-width: 280px !important; }
