@font-face {
  font-family: Sparkasse Web;
  src: url(../fonts/WOFF/Sparkasse_web_Lt.woff) format("woff");
  font-weight: 200;
  font-style:normal
}

@font-face {
  font-family: Sparkasse Web;
  src: url(../fonts/WOFF/Sparkasse_web_Md.woff) format("woff");
  font-weight: 600;
  font-style:normal
}

@font-face {
  font-family: Sparkasse Web;
  src: url(../fonts/WOFF/Sparkasse_web_Bd.woff) format("woff");
  font-weight: 700;
  font-style:normal
}

@font-face {
  font-family: Sparkasse Web;
  src: url(../fonts/WOFF/Sparkasse_web_BdIt.woff) format("woff");
  font-weight: 700;
  font-style:italic
}

@font-face {
  font-family: Sparkasse Web;
  src: url(../fonts/WOFF/Sparkasse_web_It.woff) format("woff");
  font-weight: 400;
  font-style:italic
}

@font-face {
  font-family: Sparkasse Web Head;
  src: url(../fonts/WOFF/SparkasseHead_web_Rg.woff) format("woff");
  font-weight: 700;
  font-style:normal
}


// @font-face {
//   font-family: Sparkasse Web;
//   src: url(../fonts/Sparkasse_web_Lt.eot);
//   src: url(../fonts/Sparkasse_web_Lt.woff2) format("woff2"), url(../fonts/Sparkasse_web_Lt.woff) format("woff"), url(../fonts/Sparkasse_web_Lt.ttf) format("truetype"), url(../fonts/Sparkasse_web_Lt.svg) format("svg");
//   font-weight: 200;
//   font-style:normal
// }

// @font-face {
//   font-family: Sparkasse Web;
//   src: url(../fonts/Sparkasse_web_Md.eot);
//   src: url(../fonts/Sparkasse_web_Md.woff2) format("woff2"), url(../fonts/Sparkasse_web_Md.woff) format("woff"), url(../fonts/Sparkasse_web_Md.ttf) format("truetype"), url(../fonts/Sparkasse_web_Md.svg) format("svg");
//   font-weight: 600;
//   font-style:normal
// }

// @font-face {
//   font-family: Sparkasse Web;
//   src: url(../fonts/Sparkasse_web_Bd.eot);
//   src: url(../fonts/Sparkasse_web_Bd.woff2) format("woff2"), url(../fonts/Sparkasse_web_Bd.woff) format("woff"), url(../fonts/Sparkasse_web_Bd.ttf) format("truetype"), url(../fonts/Sparkasse_web_Bd.svg) format("svg");
//   font-weight: 700;
//   font-style:normal
// }

// @font-face {
//   font-family: Sparkasse Web;
//   src: url(../fonts/Sparkasse_web_BdIt.eot);
//   src: url(../fonts/Sparkasse_web_BdIt.woff2) format("woff2"), url(../fonts/Sparkasse_web_BdIt.woff) format("woff"), url(../fonts/Sparkasse_web_BdIt.ttf) format("truetype"), url(../fonts/Sparkasse_web_BdIt.svg) format("svg");
//   font-weight: 700;
//   font-style:italic
// }

// @font-face {
//   font-family: Sparkasse Web;
//   src: url(../fonts/Sparkasse_web_It.eot);
//   src: url(../fonts/Sparkasse_web_It.woff2) format("woff2"), url(../fonts/Sparkasse_web_It.woff) format("woff"), url(../fonts/Sparkasse_web_It.ttf) format("truetype"), url(../fonts/Sparkasse_web_It.svg) format("svg");
//   font-weight: 400;
//   font-style:italic
// }

// @font-face {
//   font-family: Sparkasse Web Head;
//   src: url(../fonts/SparkasseHead_web_Rg.eot);
//   src: url(../fonts/SparkasseHead_web_Rg.woff2) format("woff2"), url(../fonts/SparkasseHead_web_Rg.woff) format("woff"), url(../fonts/SparkasseHead_web_Rg.ttf) format("truetype"), url(../fonts/SparkasseHead_web_Rg.svg) format("svg");
//   font-weight: 700;
//   font-style:normal
// }

