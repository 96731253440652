@font-face {
  font-family: Sparkasse Web;
  src: url(../fonts/WOFF/Sparkasse_web_Lt.woff) format("woff");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: Sparkasse Web;
  src: url(../fonts/WOFF/Sparkasse_web_Md.woff) format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: Sparkasse Web;
  src: url(../fonts/WOFF/Sparkasse_web_Bd.woff) format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: Sparkasse Web;
  src: url(../fonts/WOFF/Sparkasse_web_BdIt.woff) format("woff");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: Sparkasse Web;
  src: url(../fonts/WOFF/Sparkasse_web_It.woff) format("woff");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: Sparkasse Web Head;
  src: url(../fonts/WOFF/SparkasseHead_web_Rg.woff) format("woff");
  font-weight: 700;
  font-style: normal; }

ul ul,
ol ol {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: disc; }

ol ol {
  list-style-type: inherit; }

nav a {
  display: block;
  text-decoration: none; }

address {
  font-style: normal; }

hr {
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #808080;
  width: 100%;
  margin: 1em auto; }

caption {
  caption-side: bottom; }

caption:before,
caption:after {
  content: "\0020\2014\0020"; }

th,
td {
  border-top: 1px solid;
  padding: em(4px) 0;
  text-align: left;
  vertical-align: top; }

th {
  border-top: 1px solid; }

table ul {
  margin: 0;
  padding-left: 1em; }

table tr:first-child th,
table tr:first-child td {
  border-top: none; }

figure > img {
  display: block;
  margin: 0 auto; }

figcaption {
  margin: 1em 0; }

fieldset {
  padding: 0;
  margin: 0; }

label {
  cursor: pointer; }

.vh {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.contain,
.no-flexbox .main-nav,
.box {
  *zoom: 1; }

.contain:before,
.no-flexbox .main-nav:before,
.box:before,
.contain:after,
.no-flexbox .main-nav:after,
.box:after {
  content: "";
  display: table; }

.contain:after,
.no-flexbox .main-nav:after,
.box:after {
  clear: both; }

[aria-hidden] {
  display: none; }

[aria-hidden="false"] {
  display: block; }

button,
[role="button"] {
  cursor: pointer; }

button > *,
[role="button"] > * {
  pointer-events: none; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }

button {
  background: none;
  border: none;
  padding: 0;
  margin: 0; }

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html,
body {
  -webkit-text-size-adjust: 100%; }

html {
  background-color: #FFF; }

@media screen and (min-width: 320px) {
  head {
    font-family: 'tiny-1'; } }

@media screen and (min-width: 480px) {
  head {
    font-family: 'small-2'; } }

@media screen and (min-width: 600px) {
  head {
    font-family: 'medium-3'; } }

@media screen and (min-width: 768px) {
  head {
    font-family: 'large-4'; } }

@media screen and (min-width: 1000px) {
  head {
    font-family: 'xlarge-5'; } }

body {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif; }

.ieLegacy body {
  max-width: none;
  width: 1000px; }

.no-svg [src$="svg"] {
  display: none; }

.svg [src$="download.svg"] + span {
  display: none; }

[role="banner"] {
  position: relative;
  height: 200px;
  background: transparent url("../img/header.jpg") center center no-repeat;
  background-size: cover; }
  @media (min-width: 1000px) {
    [role="banner"] {
      height: 25vw; } }

[role="banner"] .logo {
  display: inline-block;
  background-color: red;
  padding: 8px 16px 4px 16px; }
  @media (min-width: 1000px) {
    [role="banner"] .logo {
      padding: 20px 50px 20px 50px; } }

[role="banner"] .logo img {
  width: 160px;
  height: auto; }
  @media (min-width: 1000px) {
    [role="banner"] .logo img {
      width: 240px; } }

[role="banner"] > h1 > img {
  display: none; }

.main-nav {
  position: relative;
  background-color: red;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.05); }

@media screen and (min-width: 1000px) {
  .main-nav {
    position: sticky;
    top: 0;
    z-index: 200; } }

.main-nav a:link,
.main-nav a:visited,
.main-nav a {
  color: #FFF;
  font-weight: 700; }

.main-nav a:focus,
.main-nav a:active,
.no-touch .main-nav a:hover {
  color: #f00;
  background-color: #fff; }

.main-nav > .container > ol {
  background-color: red;
  -webkit-transition: max-height 350ms 0ms ease-out;
  transition: max-height 350ms 0ms ease-out;
  overflow: hidden;
  max-height: 99em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10; }

@media screen and (max-width: 999px) {
  .main-nav > .container > ol[aria-hidden] {
    display: block; }
  .main-nav > .container > ol[aria-hidden='true'] {
    max-height: 0; } }

@media screen and (min-width: 1000px) {
  .main-nav > .container > ol {
    position: static;
    height: 64px;
    max-height: none;
    overflow: visible;
    background-color: transparent;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin-left: -1.5rem; }
  .main-nav > .container > ol[aria-hidden] {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; } }

.main-nav > .container > ol > li {
  position: relative;
  white-space: nowrap;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 64px; }

.main-nav > .container > ol > li.has-subnav {
  height: auto;
  min-height: 64px; }

@media screen and (min-width: 1000px) {
  .main-nav > .container > ol > li.has-subnav {
    min-height: 0;
    display: table; } }

.svg .main-nav > .container > ol > li.home span {
  display: none; }

.main-nav > .container > ol > li[role="search"] {
  height: auto;
  min-height: none; }

@media screen and (min-width: 1000px) {
  .main-nav > .container > ol > li[role="search"] {
    min-height: 3.5rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; } }

.main-nav > .container > ol > li .btn-arrow {
  height: 64px;
  width: 4rem;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  will-change: transform; }

.main-nav > .container > ol > li .btn-arrow[aria-pressed="true"] {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.main-nav > .container > ol > li .btn-arrow[aria-pressed="false"] {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0); }

.main-nav > .container > ol > li .btn-arrow path,
.main-nav > .container > ol > li .btn-arrow polyline {
  stroke: #fff;
  fill: transparent; }

@media screen and (min-width: 1000px) {
  .main-nav > .container > ol > li .btn-arrow {
    display: none; } }

.main-nav > .container > ol > li.is-active {
  background-color: #fff; }

.main-nav > .container > ol > li.is-active > a {
  color: #f00; }

.main-nav > .container > ol > li.is-active .btn-arrow path,
.main-nav > .container > ol > li.is-active .btn-arrow polyline {
  stroke: #f00; }

@media screen and (min-width: 1000px) {
  .main-nav > .container > ol > li.is-active {
    background-color: transparent; }
  .main-nav > .container > ol > li.is-active > a {
    color: #fff; } }

@media screen and (min-width: 1000px) {
  .main-nav > .container > ol > li.active {
    background-color: #fff; }
  .main-nav > .container > ol > li.active > a {
    color: #f00; }
  .main-nav > .container > ol > li.active .btn-arrow path,
  .main-nav > .container > ol > li.active .btn-arrow polyline {
    stroke: #f00; } }

.main-nav > .container > ol > li > a {
  margin-left: 1.111111111111111em; }

@media screen and (min-width: 1000px) {
  .main-nav > .container > ol > li > a {
    display: table-cell;
    height: 64px;
    vertical-align: middle;
    margin-left: 0;
    padding: 0 1.5rem; } }

@media screen and (min-width: 1000px) {
  .main-nav > .container > ol > li {
    display: block;
    padding: 0;
    height: 64px;
    min-height: 64px; }
  .no-touch .main-nav > .container > ol > li:hover {
    background-color: transparent; }
  .no-touch .main-nav > .container > ol > li:hover ol {
    display: block; }
  .no-touch .main-nav > .container > ol > li:hover > a {
    color: #f00;
    background-color: #fff; }
  .no-flexbox .main-nav > .container > ol > li > a {
    height: 35px; } }

.main-nav > .container > ol ol {
  width: 100%;
  -webkit-transition: max-height 350ms 0ms ease-out;
  transition: max-height 350ms 0ms ease-out;
  overflow: hidden;
  max-height: 99em;
  background-color: #e1ebe5; }

.main-nav > .container > ol ol[aria-hidden] {
  display: block; }

.main-nav > .container > ol ol[aria-hidden='true'] {
  max-height: 0; }

.main-nav > .container > ol ol li:first-child {
  display: none; }

@media screen and (min-width: 1000px) {
  .main-nav > .container > ol ol {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: auto;
    min-width: 100%;
    max-height: none;
    height: auto;
    padding: 2em 0 1em;
    z-index: 90;
    border-bottom: 3px solid #f00; }
  .main-nav > .container > ol ol[aria-hidden] {
    display: none; }
  .main-nav > .container > ol ol.is-active {
    display: block; }
  .touch .main-nav > .container > ol ol li:first-child {
    display: block; }
  .csstransforms .main-nav > .container > ol ol {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .csstransforms .main-nav > .container > ol ol:before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(50% - 1em);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 1em 1em 0 1em;
    border-color: #fff transparent transparent transparent; } }

.main-nav > .container > ol ol a {
  display: block;
  line-height: 2.461538461538462;
  margin: 0 1.111111111111111em;
  padding: 0.5em 0;
  border-bottom: 1px solid #c8c6c2; }

@media screen and (min-width: 1000px) {
  .main-nav > .container > ol ol a {
    border: none;
    margin: 0;
    padding: 0 1.846153846153846em; } }

.main-nav > .container > ol ol li:last-child a {
  border: none; }

.main-nav > .container > ol ol a:link,
.main-nav > .container > ol ol a:visited,
.main-nav > .container > ol ol a {
  color: #111; }

.main-nav > .container > ol ol a:focus,
.main-nav > .container > ol ol a:active,
.no-touch .main-nav > .container > ol ol a:hover {
  color: #fff;
  background-color: #f00; }

.main-nav .home {
  display: none; }

@media screen and (min-width: 1000px) {
  .main-nav .home {
    display: block; } }

.main-nav .home a:focus polyline,
.no-touch .main-nav .home a:hover polyline,
.main-nav .home a:active polyline {
  stroke: #f00; }

.main-nav .btn-menu {
  position: absolute;
  right: 0;
  bottom: 100%;
  width: 125px;
  height: 50px;
  padding-left: 1em;
  color: #fff;
  text-align: left;
  background-color: #f00; }

.main-nav .btn-menu svg {
  margin-left: 0.75em;
  vertical-align: text-bottom; }

.main-nav .btn-menu svg path,
.main-nav .btn-menu svg rect {
  fill: #fff; }

.main-nav .btn-menu[aria-pressed="true"] {
  width: 62px;
  height: 50px; }

.main-nav .btn-menu[aria-pressed="true"] span {
  display: none; }

.main-nav .btn-menu[aria-pressed="true"] svg {
  position: absolute;
  left: 21px;
  top: 0.722222222222222em;
  margin-left: 0; }

.main-nav .btn-menu[aria-pressed="true"] .menu {
  display: none; }

.main-nav .btn-menu[aria-pressed="false"] .close {
  display: none; }

@media screen and (min-width: 1000px) {
  .main-nav .btn-menu {
    display: none; } }

[role="search"] {
  padding: 0 1.111111111111111em; }

@media screen and (min-width: 1000px) {
  [role="search"] {
    border: none;
    margin: 0;
    padding: 0; }
  .no-flexbox [role="search"] {
    padding-top: 0.5em; } }

[role="search"] form {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #fff; }

@media screen and (min-width: 1000px) {
  [role="search"] form {
    border: none;
    width: auto;
    padding: 0; } }

[role="search"] input {
  -webkit-appearance: none;
  appearance: none;
  width: 76.875%;
  height: 2.133333333333333em;
  margin: 1.933333333333333em 0.666666666666667em 1.933333333333333em 0;
  border: 1px solid #fff;
  border-radius: 0; }

@media screen and (min-width: 1000px) {
  [role="search"] input {
    height: 1.923076923076923em;
    margin: 0 0.769230769230769em;
    border-color: #727272;
    width: 125px; } }

[role="search"] button span {
  display: none; }

.no-svg [role="search"] button span {
  display: inline;
  color: #fff; }

[role="search"] button svg path {
  fill: #fff; }

.breadcrumb {
  width: 100%;
  padding-top: 2rem;
  margin-bottom: 3rem;
  color: #111 !important;
  font-size: 0.875rem; }

.breadcrumb li {
  display: inline-block; }

.breadcrumb li:after {
  content: '\00A0\203A\00A0';
  color: #111; }

.breadcrumb li:last-child:after {
  content: none; }

.breadcrumb a:link,
.breadcrumb a:visited,
.breadcrumb a {
  display: inline-block;
  color: #111 !important; }

.breadcrumb a:focus,
.breadcrumb a:active,
.no-touch .breadcrumb a:hover {
  text-decoration: underline; }

[role="main"] {
  display: block;
  background-color: #fff;
  color: #111; }

[role="main"] .content {
  margin-bottom: 3em; }

@media screen and (min-width: 768px) {
  [role="main"] .content {
    margin-bottom: 3.461538461538462em; } }

[role="main"] .content ul,
.wysiwyg ul {
  margin-bottom: 1.2em;
  list-style-type: none; }

@media screen and (min-width: 768px) {
  [role="main"] .content ul,
  .wysiwyg ul {
    margin-bottom: 1.384615384615385em; } }

[role="main"] .content ul li,
.wysiwyg ul li {
  position: relative;
  left: 1em;
  padding-right: 1em; }

[role="main"] .content ul li a,
.wysiwyg ul li a {
  color: #ff0000 !important; }

[role="main"] .content ul li:before,
.wysiwyg ul li:before {
  content: '\2022';
  width: 0;
  position: relative;
  float: left;
  color: #f00;
  left: -1em; }

[role="main"] .content ul.downloads li
.wysiwyg ul.downloads li {
  position: static;
  left: 0;
  padding: 0; }

[role="main"] .content ul.downloads li:before,
.wysiwyg ul.downloads li:before {
  content: none; }

[role="main"] .content ul.downloads li a,
.wysiwyg ul.downloads li a {
  margin-left: 0.533333333333333em; }

@media screen and (min-width: 768px) {
  [role="main"] .content ul.downloads li a,
  .wysiwyg ul.downloads li a {
    margin-left: 0.615384615384615em; } }

[role="main"] .content ol,
.wysiwyg ol {
  margin-bottom: 1.2em;
  counter-reset: orderedlist; }

@media screen and (min-width: 768px) {
  [role="main"] .content ol,
  .wysiwyg ol {
    margin-bottom: 1.384615384615385em; } }

[role="main"] .content ol li,
.wysiwyg ol li {
  position: relative;
  left: 1.3em;
  padding-right: 1.3em; }

[role="main"] .content ol li:before,
.wysiwyg ol li:before {
  counter-increment: orderedlist;
  content: counter(orderedlist) ". ";
  width: 0;
  position: relative;
  float: left;
  left: -1.3em; }

@media screen and (min-width: 600px) {
  [role="main"] .content .box > div {
    float: left;
    width: 48.3333333333333%; }
  [role="main"] .content .box .box-title + div:nth-last-child(2) {
    margin-right: 3.3333333333333%;
    padding-right: 0; }
  [role="main"] .content .box .box-title + div:nth-last-child(2) + div {
    padding: 1.153846153846154em 1.153846153846154em 0 0; }
  [role="main"] .content .box .box-title:nth-last-child(4) ~ div {
    float: none;
    width: 100%; } }

@media screen and (min-width: 768px) {
  [role="main"] .content .box .box-title:nth-last-child(4) ~ div {
    float: left;
    width: 33.3333333333333%;
    padding-right: 2rem;
    padding-bottom: 0; } }

[role="main"] .page-title {
  color: red;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1em; }

[role="main"] h3 + .news,
[role="main"] h3 + .downloads,
[role="main"] h3 + .half,
[role="main"] h3 + .half + .half {
  margin-top: 1.2em; }

@media screen and (min-width: 768px) {
  [role="main"] h3 + .news,
  [role="main"] h3 + .downloads,
  [role="main"] h3 + .half,
  [role="main"] h3 + .half + .half {
    margin-top: 1.384615384615385em; } }

[role="main"] .teaser,
[role="main"] p {
  margin-bottom: 1.2em; }

@media screen and (min-width: 768px) {
  [role="main"] .teaser,
  [role="main"] p {
    margin-bottom: 1.384615384615385em; } }

[role="main"] h4,
[role="main"] h3,
[role="main"] strong {
  font-weight: 700; }

[role="main"] hr {
  clear: both;
  border-color: #dedcd6;
  margin: 1.2em 0; }

@media screen and (min-width: 768px) {
  [role="main"] hr {
    margin: 1.384615384615385em 0; } }

[role="main"] figure {
  margin-bottom: 1.2em; }

@media screen and (min-width: 600px) {
  [role="main"] figure {
    float: left;
    margin-right: 1em;
    margin-bottom: 1.384615384615385em; } }

[role="main"] figure.single,
[role="main"] figure.full {
  float: none;
  margin-right: 0;
  width: 100%; }

[role="main"] figure.single img,
[role="main"] figure.full img {
  margin: 0; }

[role="main"] figure.full img {
  width: 100%; }

[role="main"] a:link,
[role="main"] a:visited,
[role="main"] a {
  color: #f00; }

[role="main"] a:focus,
[role="main"] a:active,
.no-touch [role="main"] a:hover {
  text-decoration: underline; }

[role="main"] .highlight {
  color: #fff;
  background: #f00;
  padding: 1em;
  margin-bottom: 1.2em; }

@media screen and (min-width: 768px) {
  [role="main"] .highlight {
    padding: 1.153846153846154em;
    margin-bottom: 1.384615384615385em; } }

[role="main"] .highlight p:last-child {
  margin-bottom: 0; }

[role="main"] .highlight a:link,
[role="main"] .highlight a:visited,
[role="main"] .highlight a {
  color: #fff;
  text-decoration: underline; }

[role="main"] .highlight a:focus,
[role="main"] .highlight a:active,
.no-touch [role="main"] .highlight a:hover {
  text-decoration: none; }

[role="main"] [typeof="Person"] img {
  display: block;
  margin-bottom: 1em; }

[role="main"] [typeof="Person"] [property="name"] {
  font-weight: 700; }

[role="main"] [typeof="Person"] [property="jobTitle"],
[role="main"] [typeof="Person"] [property="email"] {
  display: block; }

[role="main"] [typeof="Person"] [property="email"]:before {
  content: '\2192\00A0';
  speak: none; }

[role="main"] [typeof="Event"] {
  margin-bottom: 1.8em;
  border-bottom: 1px solid #999; }

@media screen and (min-width: 768px) {
  [role="main"] [typeof="Event"] {
    margin-bottom: 2.076923076923077em; } }

[role="main"] [typeof="Event"] > strong {
  display: block;
  margin-bottom: 1.2em; }

@media screen and (min-width: 768px) {
  [role="main"] [typeof="Event"] > strong {
    margin-bottom: 1.384615384615385em; } }

[role="main"] [typeof="Event"] > dl {
  overflow: hidden;
  margin-bottom: 1.2em; }

@media screen and (min-width: 768px) {
  [role="main"] [typeof="Event"] > dl {
    margin-bottom: 1.384615384615385em; } }

[role="main"] [typeof="Event"] > dl dt,
[role="main"] [typeof="Event"] > dl dd {
  float: left; }

[role="main"] [typeof="Event"] > dl dt {
  font-weight: 700;
  clear: both; }

[role="main"] [typeof="Event"] > dl dt:after {
  content: ':\00A0'; }

[role="main"] [typeof="Event"] [typeof="Person"] [property="name"] {
  font-weight: normal; }

[role="main"] .teaser {
  overflow: hidden;
  border-bottom: 1px solid #999; }

[role="main"] .teaser > *:last-child a:before {
  content: '\2192\00A0';
  speak: none; }

[role="main"] .news {
  margin-bottom: 1.2em; }

@media screen and (min-width: 768px) {
  [role="main"] .news {
    margin-bottom: 1.384615384615385em; } }

[role="main"] .news .news-title {
  font-weight: normal; }

[role="main"] .site-functions {
  overflow: hidden;
  clear: both;
  margin: 2em 0; }

@media screen and (min-width: 768px) {
  [role="main"] .site-functions {
    margin: 2em 0; } }

[role="main"] .site-functions li {
  color: #adadad;
  float: left;
  margin-right: 1.153846153846154em; }

@media screen and (min-width: 768px) {
  [role="main"] .site-functions li {
    margin-right: 1.272727272727273em; } }

[role="main"] .site-functions li .print {
  color: #adadad; }

.backgroundsize [role="main"] .site-functions li .print {
  background: transparent url("../img/icons/print.png") left center no-repeat;
  -webkit-background-size: 12px 12px;
  background-size: 12px 12px;
  padding-left: 2em; }

[role="main"] .site-functions li .back-to-top:before {
  content: '\25b2';
  margin-right: 5px; }

[role="main"] .site-functions li a:link,
[role="main"] .site-functions li a:visited,
[role="main"] .site-functions li a {
  color: #adadad; }

[role="main"] .site-functions li a:focus,
[role="main"] .site-functions li a:active,
.no-touch [role="main"] .site-functions li a:hover {
  text-decoration: underline; }

.map {
  position: relative;
  margin-bottom: 1.2em; }

@media screen and (min-width: 600px) {
  [role="main"] .map {
    margin-left: 6%;
    margin-bottom: 0;
    margin-right: 0;
    width: 94%; } }

.map img {
  float: right;
  width: 41.071428571%;
  margin-bottom: 2em; }

@media screen and (min-width: 600px) {
  .map img {
    float: none;
    width: auto;
    max-width: 100%; } }

.map figcaption {
  float: left;
  width: 51.785714286%; }

@media screen and (min-width: 600px) {
  .map figcaption {
    width: auto;
    float: none;
    margin-left: 6%; } }

.map ul {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #999;
  height: 3.133333333333333em;
  overflow: hidden;
  z-index: 1; }

.ieLegacy .map ul {
  display: none; }

.map ul:before {
  content: attr(data-default);
  display: block;
  padding: 1em;
  line-height: 1em;
  background-color: #fff; }

.map ul:after {
  content: '';
  position: absolute;
  right: 1em;
  top: 50%;
  width: 10px;
  height: 18px;
  background: transparent url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2210px%22%20height%3D%2218px%22%20viewBox%3D%220%200%2010%2018%22%20xml%3Aspace%3D%22preserve%22%3E%0A%09%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23999999%22%20stroke-width%3D%221%22%20points%3D%220.354%2C17.324%208.839%2C8.839%200.354%2C0.353%22/%3E%0A%3C/svg%3E%0A") no-repeat;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  will-change: transform;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.map ul.is-active {
  overflow: visible; }

.map ul.is-active:after {
  -webkit-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg); }

@media screen and (min-width: 600px) {
  .map ul {
    position: static;
    border: none;
    height: auto;
    overflow: auto; }
  .map ul:after,
  .map ul:before {
    content: none; } }

[role="main"] .content .map li {
  position: static;
  left: 0;
  padding: 1em;
  height: 3em;
  line-height: 1em;
  background-color: #fff;
  border-left: 1px solid #999;
  border-right: 1px solid #999;
  margin-left: -1px;
  margin-right: -1px; }

[role="main"] .content .map li:before {
  content: none; }

[role="main"] .content .map li:last-child {
  border-bottom: 1px solid #999; }

[role="main"] .content .map li svg {
  border: none; }

@media screen and (min-width: 600px) {
  [role="main"] .content .map li {
    position: absolute;
    padding: 0;
    background-color: transparent;
    border: 0;
    margin: 0; }
  [role="main"] .content .map li:last-child {
    border-bottom: none; }
  [role="main"] .content .map li a {
    position: absolute;
    top: 10px;
    left: 28px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    min-width: 120px;
    padding: 0.25em 0.5em;
    text-align: center;
    background: #fff;
    -webkit-box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.6);
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.6);
    z-index: 1; }
  [role="main"] .content .map li a:before {
    content: "";
    position: absolute;
    top: calc(50% - 0.5em);
    left: -0.4em;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.5em 0.5em 0.5em 0;
    border-color: transparent #fff transparent transparent; }
  [role="main"] .content .map li.right a {
    left: auto;
    right: 23px; }
  [role="main"] .content .map li.right a:before {
    right: -0.4em;
    left: auto;
    border-width: 0.5em 0 0.5em 0.5em;
    border-color: transparent transparent transparent #fff; }
  [role="main"] .content .map li.top a {
    left: 50%;
    bottom: 114%;
    top: auto;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  [role="main"] .content .map li.top a:before {
    bottom: -0.4em;
    top: auto;
    left: calc(50% - 0.4em);
    border-width: 0.5em 0.5em 0 0.5em;
    border-color: #fff transparent transparent transparent; }
  [role="main"] .content .map li [aria-hidden='true'],
  [role="main"] .content .map li [aria-pressed="true"] + a {
    display: block; }
  [role="main"] .content .map li [aria-hidden='true'],
  [role="main"] .content .map li [aria-pressed] + a {
    display: none; }
  [role="main"] .content .map li [aria-pressed="true"] + a {
    display: block; }
  [role="main"] .content .map li#loc-1 {
    left: 53.8461538461538%;
    top: 0;
    margin-top: 107.69230769230771%; }
  [role="main"] .content .map li#loc-2 {
    left: 77.6556776556777%;
    top: 0;
    margin-top: 32.2344322344322%; }
  [role="main"] .content .map li#loc-3 {
    left: 86.8131868131868%;
    top: 0;
    margin-top: 36.2637362637363%; }
  [role="main"] .content .map li#loc-4 {
    left: 41.7582417582418%;
    top: 0;
    margin-top: 15.3846153846154%; }
  [role="main"] .content .map li#loc-5 {
    left: 15.216117%;
    top: 0;
    margin-top: 44.3223443223443%; }
  [role="main"] .content .map li#loc-6 {
    left: 2.761905%;
    top: 0;
    margin-top: 56.043956%; }
  [role="main"] .content .map li#loc-7 {
    left: 20.1465201465201%;
    top: 0;
    margin-top: 75.09157509157511%; }
  [role="main"] .content .map li#loc-8 {
    left: 19.413919413919402%;
    top: 0;
    margin-top: 91.5750915750916%; }
  [role="main"] .content .map li#loc-9 {
    margin-top: 44.3223443223443%;
    left: 23.35%;
    top: 0; }
  [role="main"] .content .map li#loc-10 {
    margin-top: 50.322344%;
    left: 8.5%;
    top: 0; }
  [role="main"] .content .map li#loc-11 {
    margin-top: 60.322344%;
    left: 51%;
    top: 0; }
  [role="main"] .content .map li#loc-12 {
    margin-top: 21.84%;
    left: 22%;
    top: 0; } }

.content .ex-map {
  position: relative; }

.content .ex-map img {
  width: 100%; }

@media screen and (min-width: 768px) {
  .content .ex-map img {
    width: 48.3333333333333%;
    height: 136.551724137931%; } }

.content .ex-map svg {
  width: 100%; }

@media screen and (min-width: 768px) {
  .content .ex-map svg {
    width: 48.3333333333333%;
    height: 398px; } }

.content .ex-map svg #areas path {
  cursor: pointer; }

.content .ex-map svg #areas path.is-active,
.no-touch .content .ex-map svg #areas path:hover {
  fill: #f00; }

.content .ex-map svg #areas path.is-active + text + text,
.no-touch .content .ex-map svg #areas path:hover + text + text,
.content .ex-map svg #areas path.is-active + text,
.no-touch .content .ex-map svg #areas path:hover + text {
  fill: #fff;
  font-weight: 700; }

.content .ex-map .list[aria-hidden="true"] {
  display: none; }

@media screen and (min-width: 768px) {
  .content .ex-map .list {
    position: absolute;
    left: 51.66666666666671%;
    top: 0; }
  .content .ex-map .list {
    position: absolute;
    left: 51.66666666666671%;
    right: 0;
    top: 50%;
    background-color: #e9e9e9;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .content .ex-map .list:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -18px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 0 18px 20px;
    border-color: transparent transparent transparent #fff; }
  .content .ex-map .list:first-of-type:before {
    left: -20px;
    border-width: 18px 20px 18px 0;
    border-color: transparent #e9e9e9 transparent transparent; }
  .content .ex-map .list p,
  .content .ex-map .list ul {
    padding: 2em 2.666666666666667em;
    margin-bottom: 0; } }

.content .ex-map .list .list-title {
  font-weight: normal;
  margin-bottom: 1.2em; }

@media screen and (min-width: 768px) {
  .content .ex-map .list .list-title {
    color: #fff;
    background-color: #f00;
    margin-bottom: 0;
    padding: 0.461538461538462em 1.153846153846154em; } }

.content .ex-map .nav {
  position: relative;
  border: 1px solid #999;
  height: 3.133333333333333em;
  overflow: hidden;
  margin: 1.2em 0;
  z-index: 1; }

.no-touch .content .ex-map .nav {
  display: none; }

@media screen and (min-width: 768px) {
  .content .ex-map .nav {
    margin: 1.384615384615385em 0;
    width: 48.3333333333333%; } }

.content .ex-map .nav:before {
  content: attr(data-default);
  display: block;
  padding: 1em;
  line-height: 1em;
  background-color: #fff; }

.content .ex-map .nav:after {
  content: '';
  position: absolute;
  right: 1em;
  top: 50%;
  width: 10px;
  height: 18px;
  background: transparent url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2210px%22%20height%3D%2218px%22%20viewBox%3D%220%200%2010%2018%22%20xml%3Aspace%3D%22preserve%22%3E%0A%09%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23999999%22%20stroke-width%3D%221%22%20points%3D%220.354%2C17.324%208.839%2C8.839%200.354%2C0.353%22/%3E%0A%3C/svg%3E%0A") no-repeat;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  will-change: transform;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.content .ex-map .nav.is-active {
  overflow: visible; }

.content .ex-map .nav.is-active:after {
  -webkit-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg); }

.content .ex-map .nav li {
  position: static;
  padding: 1em;
  height: 3em;
  line-height: 1em;
  background-color: #fff;
  border-left: 1px solid #999;
  border-right: 1px solid #999;
  margin-left: -1px;
  margin-right: -1px; }

.content .ex-map .nav li:last-child {
  border-bottom: 1px solid #999; }

.content .ex-map .nav li a {
  display: block; }

.content .ex-map .nav li:before {
  content: none; }

.box {
  margin-bottom: 1.2em; }

@media screen and (min-width: 768px) {
  .box {
    margin-bottom: 1.384615384615385em; } }

.box .box-title {
  position: relative;
  font-weight: normal; }

.box h5,
.box strong,
.box b {
  font-weight: 700; }

.box a {
  display: block; }

.box a:before {
  content: '\2192\00A0';
  speak: none; }

.box a[href^=tel] {
  display: inline; }

.box a[href^=tel]:before {
  content: none; }

.box button {
  color: #f00; }

.box .box-list li {
  border-bottom: 1px solid #dedcd6;
  padding-bottom: 1.2em;
  margin-bottom: 1.2em; }

@media screen and (min-width: 768px) {
  .box .box-list li {
    padding-bottom: 1.384615384615385em;
    margin-bottom: 1.384615384615385em; } }

[role="main"] .content .box .box-list li {
  position: static; }

[role="main"] .content .box .box-list li:before {
  content: none; }

.box .box-list a {
  margin-top: 0.75em; }

.box .iframe-box {
  position: relative;
  width: 100%;
  padding: 87.155963303% 0 0;
  margin: 0;
  margin-bottom: 1.2em; }

@media screen and (min-width: 768px) {
  .box .iframe-box {
    margin-bottom: 1.384615384615385em; } }

.box .iframe-box iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.box input {
  display: block;
  width: 100%;
  margin-bottom: 1.384615384615385em;
  padding: 0.454545454545455em 10px;
  border: 1px solid #999; }

.box [typeof="Event"] strong {
  display: block;
  margin-bottom: 0; }

.box > .swiper-container,
.box > .swiper-wrapper {
  padding: 0;
  width: 100%; }

.box > .swiper-container .btn-next,
.box > .swiper-wrapper .btn-next,
.box > .swiper-container .btn-prev,
.box > .swiper-wrapper .btn-prev {
  position: absolute;
  background-color: #98999b;
  top: 0;
  margin-top: 29%;
  z-index: 1; }

.box > .swiper-container .is-disabled,
.box > .swiper-wrapper .is-disabled {
  display: none; }

.box > .swiper-container .btn-next,
.box > .swiper-wrapper .btn-next {
  right: 0;
  -webkit-transform: scale(-1);
  -ms-transform: scale(-1);
  transform: scale(-1); }

.box > .swiper-container .btn-prev,
.box > .swiper-wrapper .btn-prev {
  left: 0; }

.box [typeof="Person"] img {
  width: 100%; }

.box [typeof="Person"] h4,
.box [typeof="Person"] p {
  padding: 0 1em; }

@media screen and (min-width: 768px) {
  .box [typeof="Person"] h4,
  .box [typeof="Person"] p {
    padding: 0 1.153846153846154em; } }

.box > .js-slider {
  padding: 0; }

.box [typeof="PostalAdress"] {
  margin-bottom: 0; }

[role="main"] .content .box {
  clear: both;
  background-color: transparent;
  margin-top: 2.4em; }

@media screen and (min-width: 768px) {
  [role="main"] .content .box {
    margin-top: 2.76923076923077em; } }

[role="main"] .content .box a {
  display: inline; }

[role="main"] .content .box a:before {
  content: none; }

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative; }

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

.layer-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100; }

.no-rgba .layer-overlay {
  background: transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNg2AQAALUAs0e+XlcAAAAASUVORK5CYII=") repeat; }

.layer[aria-hidden='false'] + .layer-overlay {
  display: block; }

.layer {
  display: none;
  position: absolute;
  z-index: 200; }

.layer[aria-hidden='false'] {
  display: block; }

@media screen and (min-width: 1000px) {
  .layer {
    position: fixed; } }

.no-csstransforms .layer {
  left: 50%;
  margin-left: -225px;
  top: 15%;
  width: 550px; }

.csstransforms .layer {
  width: 90%;
  max-width: 550px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

@media screen and (max-height: 600px) and (orientation: landscape), screen and (max-height: 667px) and (orientation: portrait) {
  .csstransforms .layer {
    top: 50px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); } }

.layer > button {
  position: absolute;
  bottom: 100%;
  right: 0;
  width: 2.307692307692307em;
  height: 2em;
  overflow: hidden;
  background-color: #fff;
  color: #f00; }

.layer > button:before {
  content: "x";
  speak: none;
  text-transform: uppercase;
  font-weight: 700;
  display: block; }

.layer-content {
  width: 100%;
  border: 6px solid #fff;
  background-color: #f00;
  color: #fff;
  padding: 1.333333333333333em; }

.layer-content a {
  color: #fff;
  text-decoration: underline; }

@media screen and (min-width: 768px) {
  .layer-content {
    padding: 3.076923076923077em; } }

.layer-content h2 {
  margin-bottom: 1em; }

.layer-content ul,
.layer-content p {
  margin-bottom: 1.2em; }

@media screen and (min-width: 768px) {
  .layer-content ul,
  .layer-content p {
    margin-bottom: 1.384615384615385em; } }

.layer-content h4,
.layer-content h3,
.layer-content strong {
  font-weight: 700; }

.layer-content li {
  position: relative;
  left: 1em;
  padding-right: 1em; }

.layer-content li:before {
  content: '\2022';
  width: 0;
  position: relative;
  float: left;
  left: -1em; }

.InhaltFormular {
  padding: 1em;
  background-color: #e9e9e9;
  margin-bottom: 1.2em; }

@media screen and (min-width: 768px) {
  .InhaltFormular {
    padding: 1.153846153846154em; } }

@media screen and (min-width: 768px) {
  .InhaltFormular {
    margin-bottom: 1.384615384615385em; } }

.InhaltFormular input {
  margin-bottom: 1.384615384615385em;
  padding: 0.454545454545455em 10px;
  border: 1px solid #999; }

.InhaltFormular input[type="text"] {
  display: block;
  width: 100%;
  clear: both; }

.InhaltFormular input[type="submit"] {
  background-color: #f00;
  color: #fff;
  font-weight: 700; }

.InhaltFormular label {
  display: block;
  margin-bottom: 0.5em; }

@media screen and (min-width: 480px) {
  .InhaltFormular label {
    width: 35%;
    float: left;
    margin-bottom: 1em; } }

.InhaltFormular select {
  display: block;
  margin-bottom: 0.5em; }

@media screen and (min-width: 480px) {
  .InhaltFormular select {
    display: inline;
    margin-bottom: 0; } }

.InhaltFormular br {
  display: none; }

@media screen and (min-width: 480px) {
  .InhaltFormular br {
    clear: both;
    display: block; } }

.InhaltFormular .InhaltFormularRight,
.InhaltFormular .Buttons {
  clear: both; }

.InhaltFormular .InhaltFormularRight {
  margin-bottom: 1.2em; }

@media screen and (min-width: 768px) {
  .InhaltFormular .InhaltFormularRight {
    margin-bottom: 1.384615384615385em; } }

.Treffer {
  margin-bottom: 1.2em; }

@media screen and (min-width: 768px) {
  .Treffer {
    margin-bottom: 1.384615384615385em; } }

.Suchbegriff_Hervorhebung {
  font-weight: 700; }

.Treffer_Datum {
  color: #727272; }

.Treffer_Pfad {
  overflow: hidden;
  font-style: italic;
  white-space: nowrap;
  text-overflow: ellipsis; }

/* https://cookieconsent.insites.com/download/ */
.cc-window {
  opacity: 1;
  transition: opacity 1s ease; }

.cc-window.cc-invisible {
  opacity: 0; }

.cc-animate.cc-revoke {
  transition: transform 1s ease; }

.cc-animate.cc-revoke.cc-top {
  transform: translateY(-2em); }

.cc-animate.cc-revoke.cc-bottom {
  transform: translateY(2em); }

.cc-animate.cc-revoke.cc-active.cc-bottom, .cc-animate.cc-revoke.cc-active.cc-top, .cc-revoke:hover {
  transform: translateY(0); }

.cc-grower {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s; }

.cc-link, .cc-revoke:hover {
  text-decoration: underline; }

.cc-revoke, .cc-window {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.5em;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  z-index: 9999; }

.cc-window.cc-static {
  position: static; }

.cc-window.cc-floating {
  padding: 2em;
  max-width: 24em;
  -ms-flex-direction: column;
  flex-direction: column; }

.cc-window.cc-banner {
  padding: 1em 1.8em;
  width: 100%;
  -ms-flex-direction: row;
  flex-direction: row; }

.cc-revoke {
  padding: .5em; }

.cc-header {
  font-size: 18px;
  font-weight: 700; }

.cc-btn, .cc-close, .cc-link, .cc-revoke {
  cursor: pointer; }

.cc-link {
  opacity: .8;
  display: inline-block;
  padding: .2em; }

.cc-link:hover {
  opacity: 1; }

.cc-link:active, .cc-link:visited {
  color: initial; }

.cc-btn {
  display: block;
  padding: .4em .8em;
  font-size: .9em;
  font-weight: 700;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap; }

.cc-banner .cc-btn:last-child {
  min-width: 140px; }

.cc-highlight .cc-btn:first-child {
  background-color: transparent;
  border-color: transparent; }

.cc-highlight .cc-btn:first-child:focus, .cc-highlight .cc-btn:first-child:hover {
  background-color: transparent;
  text-decoration: underline; }

.cc-close {
  display: block;
  position: absolute;
  top: .5em;
  right: .5em;
  font-size: 1.6em;
  opacity: .9;
  line-height: .75; }

.cc-close:focus, .cc-close:hover {
  opacity: 1; }

.cc-revoke.cc-top {
  top: 0;
  left: 3em;
  border-bottom-left-radius: .5em;
  border-bottom-right-radius: .5em; }

.cc-revoke.cc-bottom {
  bottom: 0;
  left: 3em;
  border-top-left-radius: .5em;
  border-top-right-radius: .5em; }

.cc-revoke.cc-left {
  left: 3em;
  right: unset; }

.cc-revoke.cc-right {
  right: 3em;
  left: unset; }

.cc-top {
  top: 1em; }

.cc-left {
  left: 1em; }

.cc-right {
  right: 1em; }

.cc-bottom {
  bottom: 1em; }

.cc-floating > .cc-link {
  margin-bottom: 1em; }

.cc-floating .cc-message {
  display: block;
  margin-bottom: 1em; }

.cc-window.cc-floating .cc-compliance {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto; }

.cc-window.cc-banner {
  -ms-flex-align: center;
  align-items: center; }

.cc-banner.cc-top {
  left: 0;
  right: 0;
  top: 0; }

.cc-banner.cc-bottom {
  left: 0;
  right: 0;
  bottom: 0; }

.cc-banner .cc-message {
  -ms-flex: 1;
  flex: 1; }

.cc-compliance {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: justify;
  align-content: space-between; }

.cc-compliance > .cc-btn {
  -ms-flex: 1;
  flex: 1; }

.cc-btn + .cc-btn {
  margin-left: .5em; }

@media print {
  .cc-revoke, .cc-window {
    display: none; } }

@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal; } }

@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-top {
    top: 0; }
  .cc-window.cc-bottom {
    bottom: 0; }
  .cc-window.cc-banner, .cc-window.cc-left, .cc-window.cc-right {
    left: 0;
    right: 0; }
  .cc-window.cc-banner {
    -ms-flex-direction: column;
    flex-direction: column; }
  .cc-window.cc-banner .cc-compliance {
    -ms-flex: 1;
    flex: 1; }
  .cc-window.cc-floating {
    max-width: none; }
  .cc-window .cc-message {
    margin-bottom: 1em; }
  .cc-window.cc-banner {
    -ms-flex-align: unset;
    align-items: unset; } }

.cc-floating.cc-theme-classic {
  padding: 1.2em;
  border-radius: 5px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
  text-align: center;
  display: inline;
  -ms-flex: none;
  flex: none; }

.cc-theme-classic .cc-btn {
  border-radius: 5px; }

.cc-theme-classic .cc-btn:last-child {
  min-width: 140px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
  display: inline-block; }

.cc-theme-edgeless.cc-window {
  padding: 0; }

.cc-floating.cc-theme-edgeless .cc-message {
  margin: 2em 2em 1.5em; }

.cc-banner.cc-theme-edgeless .cc-btn {
  margin: 0;
  padding: .8em 1.8em;
  height: 100%; }

.cc-banner.cc-theme-edgeless .cc-message {
  margin-left: 1em; }

.cc-floating.cc-theme-edgeless .cc-btn + .cc-btn {
  margin-left: 0; }

/* overwrites cookies */
.cc-banner.cc-bottom,
.cc-floating.cc-bottom {
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  -webkit-box-shadow: 0 -5px 5px -5px rgba(34, 34, 34, 0.1);
  box-shadow: 0 -5px 5px -5px rgba(34, 34, 34, 0.1); }

.cc-banner.cc-bottom .cc-compliance,
.cc-floating.cc-bottom .cc-compliance {
  margin-left: 2em; }

@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
  .cc-banner.cc-bottom .cc-compliance,
  .cc-floating.cc-bottom .cc-compliance {
    margin-left: 0; } }

.cc-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 972px;
  margin: 0 auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 1000px;
  flex: 1 1 1000px; }

@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
  .cc-container {
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto; } }

.cc-btn {
  border-radius: 22px; }

.aspect-ratio {
  width: 100%;
  height: 0px;
  position: relative; }

.aspect-ratio--16-9 {
  padding-top: 56.25%; }

.aspect-ratio iframe,
.aspect-ratio video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }

html {
  font-size: 16px; }

body {
  font-family: "Sparkasse Web";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111;
  max-width: none;
  margin: 0; }

a {
  color: #111; }

h2 {
  font-size: 1.25rem;
  line-height: 1.25; }
  @media (min-width: 1000px) {
    h2 {
      font-size: 1.5rem; } }

.box .box-title {
  font-size: 1.25rem;
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 2rem; }
  @media (min-width: 1000px) {
    .box .box-title {
      font-size: 1.5rem; } }

.row {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap; }

.col {
  padding: 0 15px;
  max-width: 100%;
  flex: 0 0 100%; }
  @media (min-width: 1000px) {
    .col {
      max-width: 33.333%;
      flex: 0 0 33.333%; } }

.container {
  width: 100%;
  max-width: 1000px;
  padding: 0 15px;
  margin: 0 auto; }

@media (min-width: 1000px) {
  .content section[typeof="Person"].half {
    float: left;
    width: 33.33%; } }

aside .container {
  display: flex;
  flex-wrap: wrap; }
  aside .container .box {
    max-width: 100%;
    flex: 0 0 100%; }

.banner__logo {
  position: absolute;
  top: 16px;
  left: 0;
  z-index: 2; }
  @media (min-width: 1000px) {
    .banner__logo {
      top: 24px; } }
  .banner__logo .logo-print {
    display: none; }
  .banner__logo .logo {
    z-index: 4;
    position: relative; }

.banner__claim {
  font-size: 18px;
  display: inline-flex;
  background-color: white;
  padding: 20px 33px 10px 33px;
  margin-top: -12px;
  margin-left: 16px;
  z-index: 3;
  position: relative; }
  @media (min-width: 1000px) {
    .banner__claim {
      margin-top: -16px;
      font-size: 28px;
      padding: 26px 50px 16px 50px;
      margin-left: 50px; } }

.banner__text {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 48px;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center; }
  @media (min-width: 1000px) {
    .banner__text {
      padding-top: 96px; } }
  .banner__text h2 {
    padding-right: 200px; }
    @media (min-width: 1000px) {
      .banner__text h2 {
        padding-right: 0px; } }

.banner__text h2 {
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: 700;
  color: #FFF;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.25); }
  @media (min-width: 1000px) {
    .banner__text h2 {
      font-size: 3rem; } }

footer {
  background-color: #284450;
  color: #FFF;
  padding: 1rem 0; }
  @media (min-width: 1000px) {
    footer {
      padding: 2rem 0; } }
  footer a {
    color: #FFF;
    display: flex;
    transition: all 0.2s ease 0s; }
    footer a:hover, footer a:focus {
      transform: translate(0, -2px); }
  footer .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 64px;
    align-items: center; }
    @media (min-width: 1000px) {
      footer .container {
        justify-content: space-between; } }

.footer-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 0 0 100%; }
  @media (min-width: 1000px) {
    .footer-links {
      flex: 0 1 auto;
      justify-content: flex-start; } }
  .footer-links li {
    margin: 0 0.5rem; }
    @media (min-width: 1000px) {
      .footer-links li {
        margin-left: 0; } }

.footer-social {
  display: flex;
  justify-content: center;
  max-width: 100%;
  flex: 0 0 100%;
  margin-top: 2rem; }
  @media (min-width: 1000px) {
    .footer-social {
      margin-top: 0;
      flex: 0 1 auto;
      justify-content: flex-end; } }
  .footer-social li {
    margin: 0 1rem; }
    @media (min-width: 1000px) {
      .footer-social li {
        margin-right: 0; } }
  .footer-social svg,
  .footer-social img {
    width: 32px;
    height: 32px; }

.main-nav > .container {
  display: flex; }

.main-nav > .container > ol {
  display: flex;
  width: 100%; }

.section.marginal {
  padding: 3rem 0 2rem 0;
  background-color: #f4f2f0; }

.section--bg-green-light {
  background-color: #e1ebe5;
  padding: 3rem 0 2rem 0; }

.section--bg-grey-light {
  background-color: #f4f2f0;
  padding: 3rem 0 2rem 0; }

.section--bg-white {
  background-color: #FFF;
  padding: 3rem 0 2rem 0; }

.content + .section--bg-white,
div + .section--bg-white,
.section--bg-white + .section--bg-white {
  padding-top: 0;
  margin-top: -1rem; }

.section__title {
  color: red;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem; }

.page-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem; }

.teaser-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px; }
  .teaser-grid .teaser {
    padding-right: 30px;
    max-width: 33.333%;
    flex: 0 0 33.333%;
    border-bottom: 0; }
    .teaser-grid .teaser h2, .teaser-grid .teaser h3 {
      font-size: 1.25rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
      color: red; }
    .teaser-grid .teaser figure {
      float: none;
      margin-right: 0; }
      .teaser-grid .teaser figure img {
        margin: 0;
        width: 100%;
        height: auto; }

.grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px; }
  .grid:empty {
    display: none; }
  @media (min-width: 1000px) {
    .grid {
      margin-right: -30px; } }
  .grid .grid__item {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 15px;
    max-width: 50%;
    flex: 0 0 50%;
    display: flex; }
    @media (min-width: 1000px) {
      .grid .grid__item {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 30px;
        max-width: 33.333%;
        flex: 0 0 33.333%; } }
  .grid section[typeof="Person"].half {
    float: none;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 15px;
    max-width: 100%;
    flex: 0 0 100%; }
    @media (min-width: 768px) {
      .grid section[typeof="Person"].half {
        max-width: 50%;
        flex: 0 0 50%; } }
    @media (min-width: 1000px) {
      .grid section[typeof="Person"].half {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 30px;
        max-width: 33.333%;
        flex: 0 0 33.333%; } }

.logo-wall-image {
  padding: 15px 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1000px) {
    .logo-wall-image {
      padding: 15px 30px; } }
  .logo-wall-image img {
    width: 100%;
    height: auto;
    object-fit: contain; }

.section.marginal .container > *:not(.vh) + * {
  margin-top: 2.5rem;
  margin-bottom: 0 !important; }

.box-title {
  color: red; }

.wysiwyg:after {
  content: "";
  display: table;
  clear: both; }

.wysiwyg + .wysiwyg {
  margin-top: 1rem; }

input, select, button, textarea {
  font-family: "Sparkasse Web";
  font-weight: 400;
  font-size: inherit; }

input[type="text"],
input[type="email"],
input[type="password"],
select,
textarea {
  border: 1px solid #c8c6c2;
  padding: 0 1rem;
  height: 40px;
  width: 100%;
  border-radius: 1px; }

select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m17.3786797 16-5.8786797-5.8786797c-.5857864-.58578639-.5857864-1.53553386 0-2.1213203.5857864-.58578644 1.5355339-.58578644 2.1213203 0l6.5857865 6.5857864c.7810486.7810486.7810486 2.0473786 0 2.8284272l-6.5857865 6.5857864c-.5857864.5857864-1.5355339.5857864-2.1213203 0-.5857864-.5857864-.5857864-1.5355339 0-2.1213203z' transform='matrix(0 1 -1 0 32.03 -.03)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 24px; }
  select::ms-expand {
    display: none; }

input[type="submit"] {
  height: 40px;
  border: 0;
  border-radius: 20px;
  font-weight: 700;
  padding: 0 2rem;
  background-color: red;
  color: #FFF; }

label {
  font-weight: 700; }

.form-default > div:not(.check) {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem; }

.form-default .check {
  margin-bottom: 2rem; }
  .form-default .check label {
    display: inline-flex;
    align-items: center;
    margin-right: 1rem; }
    .form-default .check label input {
      margin-right: 8px; }

.form-default label {
  display: flex;
  padding-top: 8px;
  flex: 0 0 100%; }
  @media (min-width: 1000px) {
    .form-default label {
      min-height: 40px;
      flex: 0 0 15em; } }

.form-default label[for="pi_cf-datenschutz"] {
  display: block; }

.form-default input[type="text"],
.form-default select,
.form-default textarea {
  flex: 1; }

.form-default input#pi_cf-zip {
  margin-right: 1rem;
  flex: 0 0 8rem; }

.nl-form-body {
  background-color: transparent !important;
  margin: 0 !important;
  width: auto !important;
  font-family: inherit !important; }

.nl-form-body .element {
  padding: 0 !important;
  margin-top: 0.5rem !important; }

.nl-form-body button {
  background: red !important;
  color: white !important;
  height: 40px;
  border-radius: 20px;
  border: 0 !important;
  font-weight: bold; }

.nl-form-body p.descr {
  margin-top: 0.125rem !important; }

.nl-form-body button {
  width: auto !important;
  min-width: 280px !important; }

.card {
  margin-bottom: 2rem; }

.card__image {
  margin-bottom: 0.5rem; }
  .card__image img {
    max-width: 100%;
    height: auto; }

.card__text h2, .card__text h3 {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: red; }

.card__text p {
  margin: 0; }

.card__cta {
  margin-top: 0.5rem; }
  .card__cta a:before {
    content: '\2192\00A0'; }

.two-click-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f2f0;
  display: flex;
  align-items: center;
  padding: 2rem; }
  @media (max-width: 480px) {
    .two-click-container {
      padding: 0.5rem 0.5rem 0.5rem 1rem; } }

.two-click-disclaimer h5 {
  font-weight: bold;
  margin-bottom: 0.5em; }
  @media (max-width: 480px) {
    .two-click-disclaimer h5 {
      font-size: 13px; } }

@media (max-width: 480px) {
  .two-click-disclaimer p {
    font-size: 13px;
    line-height: 1.25;
    margin-bottom: 0.5em; } }

.two-click-disclaimer .btn {
  height: 40px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  padding: 0 2rem;
  font-weight: bold;
  margin-right: 1rem; }
  .two-click-disclaimer .btn:hover, .two-click-disclaimer .btn:focus {
    text-decoration: underline;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); }
  @media (max-width: 480px) {
    .two-click-disclaimer .btn {
      font-size: 13px;
      padding: 0 1rem;
      margin-right: 0.5rem; } }
  .two-click-disclaimer .btn.two-click-info {
    background-color: #FFF;
    color: #111; }
  .two-click-disclaimer .btn.two-click-accept {
    background-color: red;
    color: #FFF; }
