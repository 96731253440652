$color-dark: #111;
$color-light: #FFF;
$color-primary: rgb(255,0,0);
$color-green-dark: rgb(40,68,80);
$color-green-medium: rgb(196,216,204);
$color-green-light: rgb(225,235,229);
$color-grey-dark: rgb(200,198,194);
$color-grey-medium: rgb(222,220,214);
$color-grey-light: rgb(244,242,240);
$font-regular: "Sparkasse Web";

@import 'fonts.scss';
@import 'previous-styles.scss';
@import 'base';
@import 'wysiwyg';
@import 'forms';
@import 'cards';
@import 'two-click';
