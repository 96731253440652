html {
  font-size: 16px; }

body {
  font-family: $font-regular;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: $color-dark;
  max-width: none;
  margin: 0; }

a {
  color: $color-dark; }

// Typo

h2 {
  font-size: 1.25rem;
  line-height: 1.25;

  @media (min-width: 1000px) {
    font-size: 1.5rem; } }

.box .box-title {
  font-size: 1.25rem;
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 2rem;

  @media (min-width: 1000px) {
    font-size: 1.5rem; } }

// Layout and Grid

.row {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap; }

.col {
  padding: 0 15px;
  max-width: 100%;
  flex: 0 0 100%;

  @media (min-width: 1000px) {
    max-width: 33.333%;
    flex: 0 0 33.333%; } }


.container {
  width: 100%;
  max-width: 1000px;
  padding: 0 15px;
  margin: 0 auto; }

.content {

  section[typeof="Person"].half {
    @media (min-width: 1000px) {
      float: left;
      width: 33.33%; } } }


aside {
  .container {
    display: flex;
    flex-wrap: wrap;

    .box {
      max-width: 100%;
      flex: 0 0 100%; } } }

// Banner

.banner__logo {
  position: absolute;
  top: 16px;
  left: 0;
  z-index: 2;
  @media (min-width: 1000px) {
    top: 24px; }

  .logo-print {
    display: none; }

  .logo {
    z-index: 4;
    position: relative; } }

.banner__claim {
  font-size: 18px;
  display: inline-flex;
  background-color: white;
  padding: 20px 33px 10px 33px;
  margin-top: -12px;
  margin-left: 16px;
  z-index: 3;
  position: relative;

  @media (min-width: 1000px) {
    margin-top: -16px;
    font-size: 28px;
    padding: 26px 50px 16px 50px;
    margin-left: 50px; } }



.banner__text {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 48px;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  @media (min-width: 1000px) {
      padding-top: 96px; }
  h2 {
    padding-right: 200px;
    @media (min-width: 1000px) {
      padding-right: 0px; } } }




.banner__text {
  h2 {
    font-size: 1.5rem;
    line-height: 1.25;
    font-weight: 700;
    color: $color-light;
    text-shadow: 0 0 10px rgba(0,0,0,0.25);
    @media (min-width: 1000px) {
      font-size: 3rem; } } }

// Footer

footer {
  background-color: $color-green-dark;
  color: $color-light;
  padding: 1rem 0;
  @media (min-width: 1000px) {
    padding: 2rem 0; }
  a {
    color: $color-light;
    display: flex;
    transition: all 0.2s ease 0s;

    &:hover,
    &:focus {
      transform: translate(0,-2px); } }



  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 64px;
    align-items: center;
    @media (min-width: 1000px) {
      justify-content: space-between; } } }

.footer-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 0 0 100%;
  @media (min-width: 1000px) {
    flex: 0 1 auto;
    justify-content: flex-start; }

  li {
    margin: 0 0.5rem;
    @media (min-width: 1000px) {
      margin-left: 0; } } }

.footer-social {
  display: flex;
  justify-content: center;
  max-width: 100%;
  flex: 0 0 100%;
  margin-top: 2rem;
  @media (min-width: 1000px) {
    margin-top: 0;
    flex: 0 1 auto;
    justify-content: flex-end; }

  li {
    margin: 0 1rem;
    @media (min-width: 1000px) {
      margin-right: 0; } }

  svg,
  img {
    width: 32px;
    height: 32px; } }

.main-nav > .container {
  display: flex; }

.main-nav > .container > ol {
  display: flex;
  width: 100%; }

.section.marginal {
  padding: 3rem 0 2rem 0;
  background-color: $color-grey-light; }

.section--bg-green-light {
  background-color: $color-green-light;
  padding: 3rem 0 2rem 0; }

.section--bg-grey-light {
  background-color: $color-grey-light;
  padding: 3rem 0 2rem 0; }

.section--bg-white {
  background-color: $color-light;
  padding: 3rem 0 2rem 0; }

.content + .section--bg-white,
div + .section--bg-white,
.section--bg-white + .section--bg-white {
  padding-top: 0;
  margin-top: -1rem; }

.section__title {
  color: $color-primary;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem; }

.page-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem; }


.teaser-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;

  .teaser {
    padding-right: 30px;
    max-width: 33.333%;
    flex: 0 0 33.333%;
    border-bottom: 0;

    h2, h3 {
      font-size: 1.25rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
      color: $color-primary; }

    figure {
      float: none;
      margin-right: 0;

      img {
        margin: 0;
        width: 100%;
        height: auto; } } } }

.grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  &:empty {
    display: none; }
  @media (min-width: 1000px) {
    margin-right: -30px; }

  .grid__item {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 15px;
    max-width: 50%;
    flex: 0 0 50%;
    display: flex;

    @media (min-width: 1000px) {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 30px;
      max-width: 33.333%;
      flex: 0 0 33.333%; } }

  section[typeof="Person"].half {
    float: none;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 15px;
    max-width: 100%;
    flex: 0 0 100%;

    @media (min-width: 768px) {
      max-width: 50%;
      flex: 0 0 50%; }

    @media (min-width: 1000px) {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 30px;
      max-width: 33.333%;
      flex: 0 0 33.333%; } } }

.logo-wall-image {
  padding: 15px 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1000px) {
    padding: 15px 30px; }

  img {
    width: 100%;
    height: auto;
    object-fit: contain; } }


.section.marginal {
  .container {
    > *:not(.vh) + * {
      margin-top: 2.5rem;
      margin-bottom: 0 !important; } } }

.box-title {
  color: $color-primary; }
