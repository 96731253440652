.two-click-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-grey-light;
  display: flex;
  align-items: center;
  padding: 2rem;
  @media (max-width: 480px) {
    padding: 0.5rem 0.5rem 0.5rem 1rem; } }

.two-click-disclaimer {

  h5 {
    font-weight: bold;
    margin-bottom: 0.5em;

    @media (max-width: 480px) {
      font-size: 13px; } }

  p {
    @media (max-width: 480px) {
      font-size: 13px;
      line-height: 1.25;
      margin-bottom: 0.5em; } }

  .btn {
    height: 40px;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    padding: 0 2rem;
    font-weight: bold;
    margin-right: 1rem;

    &:hover,
    &:focus {
      text-decoration: underline;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1); }

    @media (max-width: 480px) {
      font-size: 13px;
      padding: 0 1rem;
      margin-right: 0.5rem; }

    &.two-click-info {
      background-color: $color-light;
      color: $color-dark; }

    &.two-click-accept {
      background-color: $color-primary;
      color: $color-light; } } }
